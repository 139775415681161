import dynamic from "next/dynamic";

import { useCallback } from "react";

import { twMerge } from "tailwind-merge";

import Modal from "components/Modal";

const SignInForm = dynamic(() => import("components/SignInForm"));
const SignUpForm = dynamic(() => import("components/SignUpForm"));

export type ModalType = "sign-in" | "sign-up" | undefined;

type SignInSignUpModalProps = {
  type: ModalType;
  isOpen: boolean;
  onClose?: () => void;
  onSwitch?: () => void;
};

const SignInSignUpModal = ({
  type,
  isOpen,
  onClose: customOnClose,
  onSwitch,
}: SignInSignUpModalProps) => {
  const onClose = useCallback(() => {
    if (customOnClose) {
      return customOnClose();
    }
  }, [customOnClose]);

  return (
    <Modal
      className={twMerge(
        type === "sign-in"
          ? "md:w-[520px] md:rounded-[8px] p-[20px]"
          : "md:w-[750px] md:rounded-[16px]"
      )}
      closeCTAClassName={twMerge(type === "sign-up" && "text-white")}
      isOpen={isOpen}
      onClose={onClose}
    >
      {type === "sign-in" ? (
        <SignInForm onSwitch={onSwitch} />
      ) : (
        <SignUpForm
          border={false}
          onSwitch={onSwitch}
          type="insight-signup"
          isModal
        />
      )}
    </Modal>
  );
};

export default SignInSignUpModal;
